<template>
  <div>
    <HomeSectionTitle
      :title="title"
      :loading="isLoading"
      :tag-line="tagLine"
      :link="seeMoreLink"
      @on-link-clicked="onSeeMoreClicked"
    />
    <Suspense>
      <template v-if="!props.isLoading">
        <MoreToExploreView v-bind="props" />
      </template>
      <template v-else>
        <RestaurantCardSlider
          :restaurants="restaurants"
          :slide-per-view="slidePerView"
        />
      </template>
      <template #fallback>
        <RestaurantCardSlider
          :restaurants="restaurants"
          :slide-per-view="slidePerView"
        />
      </template>
    </Suspense>
  </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from "vue";
import type { RestaurantCardProps } from "~/types/Restaurant";
import RestaurantCardSlider from "~/section/restaurant_card_slider/RestaurantCardSlider.vue";

interface Props {
  restaurants: RestaurantCardProps[];
  slidePerView: number | string;
  isLoading: boolean;
  sideImage: boolean;
  title: string;
  tagLine?: string;
  seeMoreLink: string;
}

const props = defineProps<Props>();
const emits = defineEmits<{
  (
    e: "on-card-clicked",
    data: { card: RestaurantCardProps; position: number }
  ): void;
  (e: "on-see-more-clicked", data: string): void;
}>();
const MoreToExploreView = defineAsyncComponent(
  () => import("./MoreToExploreView.vue")
);

function onSeeMoreClicked(link: string) {
  emits("on-see-more-clicked", link);
}
</script>
<script lang="ts">
export default {
  name: "MoreToExplore",
};
</script>
